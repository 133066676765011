<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Fleet Management" backTo="internal">
      <is-authorized :permissions="['manage:fleets', 'create:fleets']">
        <router-link
          :to="{ name: 'vehicle-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Create Vehicle</span>
        </router-link>
      </is-authorized>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-2">
            <button
              @click="selectTab('all')"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'all',
                'hover:bg-gray-200': currentTab != 'all',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
              </svg>
              <span>Manage Vehicles</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex flex-1 justify-end">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search vehicle by registration, make, model"
              v-model="searchTerm"
              @keydown.enter="searchFleets"
            />
            <button
              @click="searchFleets"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div v-if="viewState == 'Idle'">
          <div v-if="currentTab == 'all'">
            <DataTable
              class="mt-5"
              stripedRows
              scrollHeight="600px"
              :value="fleets"
              :paginator="true"
              :rows="10"
              selectionMode="single"
              @row-select="onFleetRowSelected"
              @rows="onPageChange"
              @page="onPerPageChange"
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10, 20, 50]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            >
              <Column
                field="license_plate"
                header="Vehicle Registration"
              ></Column>
              <Column field="manufacturer" header="Manufacturer"></Column>
              <Column field="make" header="Model"></Column>
              <Column field="colour" header="Colour"></Column>
              <Column field="assigned_to" header="Assigned to">
                <template #body="slotProps">
                  {{ getAssigned(slotProps.data) }}
                </template>
              </Column>

              <Column header="Tax Expiry">
                <template #body="slotProps">
                  <span
                    :class="
                      'expiry-badge status-' +
                      getExpiryStatus(slotProps.data.tax_expiry)
                    "
                  >
                    {{ formatDate(slotProps.data.tax_expiry) }}
                  </span>
                </template>
              </Column>

              <Column header="MOT Expiry">
                <template #body="slotProps">
                  <span
                    v-if="slotProps.data.mot && slotProps.data.mot.length > 0"
                    :class="
                      'expiry-badge status-' +
                      getExpiryStatus(slotProps.data.mot[0].expiry_date)
                    "
                  >
                    {{ formatDate(slotProps.data.mot[0].expiry_date) }}
                  </span>
                  <span v-else class="expiry-badge status-unknown">
                    No MOT Data
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <div v-if="currentTab == 'search'">
            <div v-show="searchState == 'Idle'">
              <h4 class="mt-5" v-if="searchTermWas">
                {{ totalSearchResults }} search results for '{{
                  searchTermWas
                }}'
              </h4>

              <DataTable
                class="mt-5"
                stripedRows
                scrollHeight="600px"
                :value="searchResults"
                :paginator="true"
                :rows="10"
                selectionMode="single"
                @row-select="onFleetRowSelected"
                @rows="onPageChange"
                @page="onPerPageChange"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              >
                <Column
                  field="license_plate"
                  header="Vehicle Registration"
                ></Column>
                <Column field="manufacturer" header="Manufacturer"></Column>
                <Column field="make" header="Model"></Column>
                <Column field="colour" header="Colour"></Column>
                <Column field="assigned_to" header="Assigned to">
                  <template #body="slotProps">
                    {{ getAssigned(slotProps.data) }}
                  </template>
                </Column>
              </DataTable>
              <!--              <Table-->
              <!--                class="mt-5"-->
              <!--                :columns="columns"-->
              <!--                :data="searchResults"-->
              <!--                :totalRecords="totalSearchResults"-->
              <!--                :hasPagination="false"-->
              <!--                :noDataMessage="-->
              <!--                  searchTermWas &&-->
              <!--                    searchResults.length == 0 &&-->
              <!--                    `No results for ${searchTermWas}`-->
              <!--                "-->
              <!--              />-->
            </div>
            <div
              v-if="searchState == 'Searching'"
              class="flex items-center justify-center py-20"
            >
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again.
                If the problem persists, please contact your support team.
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="viewState == 'Loading'"
          class="flex items-center justify-center py-20"
        >
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import Table from "@/components/Table.vue";
import Enumerable from "linq";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  components: {
    PageHeader,
    Spinner,
    Panel,
    Table,
    IsAuthorized,
  },
  data() {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "all",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      totalSearchResults: 0,
      error: null,
      totalRecords: 0,
      vehicleToEdit: {},
      pagingParams: {
        page: 1,
        limit: 50,
      },
      searchPagingParams: {
        page: 1,
        limit: 50,
      },
      fleets: [],
      columns: [
        {
          title: "Vehicle Registration",
          field: "license_plate",
          sortable: false,
        },
        {
          title: "Manufacturer",
          field: "manufacturer",
          sortable: false,
        },
        {
          title: "Model",
          field: "make",
          sortable: false,
        },
        {
          title: "Colour",
          field: "colour",
          sortable: false,
        },
        {
          title: "Assigned to",
          field: "assigned_to",
          selector: this.getAssigned,
          sortable: false,
        },
      ],
      showingCreateFleetPanel: false,
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadFleets();
      this.searchTerm = this.$store.state.fleetSearchTerm;
      await this.searchFleets();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    getAssigned(rowObj) {
      if (!rowObj || rowObj.assignment <= 0) {
        return "";
      }
      var assignment = Enumerable.from(rowObj.assignment)
        .where((c) => c.is_active)
        .orderBy((item) => item.requisition_enddate)
        .firstOrDefault();
      if (assignment) {
        return assignment.staff_name;
      } else {
        return "";
      }
    },
    selectTab: function (tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setFleetSearchTerm", "");
    },

    async searchFleets() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setFleetSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let results = await this.FleetService.searchFleet(
          this.searchTerm,
          this.searchPagingParams,
        );

        this.searchResults = results.data;
        this.totalSearchResults = results.count;
        this.searchTermWas = this.searchTerm;
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },

    async loadFleets() {
      let fleetsResult = await this.FleetService.getFleets(this.pagingParams);

      this.fleets = fleetsResult.data;
      this.totalRecords = fleetsResult.count;
    },

    onFleetRowSelected(selectedRow) {
      console.log(selectedRow);
      this.$store.dispatch("setLastSelectedFleet", selectedRow.data.fleet_id);
      this.$router.push({
        name: "fleets-edit",
        params: { fleetId: selectedRow.data.fleet_id },
      });
    },

    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      if (this.currentTab == "search") {
        await this.searchFleets();
      } else {
        await this.loadFleets();
      }
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.rows });
      if (this.currentTab == "search") {
        await this.searchFleets();
      } else {
        await this.loadFleets();
      }
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });
      if (this.currentTab == "search") {
        await this.searchFleets();
      } else {
        await this.loadFleets();
      }
    },

    markRowAsSelected: function (row) {
      return this.$store.state.lastSelectedFleet == row.fleet_id
        ? "bg-green-300"
        : "";
    },

    updatePagingParams(newProps) {
      if (this.currentTab == "search") {
        this.searchPagingParams = Object.assign(
          {},
          this.searchPagingParams,
          newProps,
        );
      } else {
        this.pagingParams = Object.assign({}, this.pagingParams, newProps);
      }
    },

    getExpiryStatus(expiryDate) {
      const today = this.$moment();
      const expiry = this.$moment(expiryDate);
      const daysDiff = expiry.diff(today, "days");

      if (daysDiff > 30) return "green";
      if (daysDiff > 0) return "orange";
      return "red";
    },
    formatDate(date) {
      return date ? this.$moment(date).format("LL") : "N/A";
    },
  },
};
</script>
